import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';
import {
    PRIMARY, RED_VIOLET, SECONDARY, WHITE,
} from '@theme_color';
import { FONT_24, FONT_REGULAR } from '@theme_typography';
import { BREAKPOINTS } from '@theme_vars';

// Create a theme instance.
const theme = (props) => {
    const { primaryColor } = props;

    return createMuiTheme({
        typography: {
            fontFamily: ['Roboto', 'sans-serif'],
        },
        palette: {
            primary: {
                main: PRIMARY,
            },
            secondary: {
                main: SECONDARY,
            },
            error: {
                main: red.A400,
            },
            background: {
                default: WHITE,
            },
        },
        breakpoints: {
            values: {
                ...BREAKPOINTS,
            },
        },
        overrides: {
            MuiGrid: {
                root: {
                    padding: 0,
                    margin: 0,
                },
            },
            MuiTextField: {
                root: {
                    ...FONT_REGULAR,
                },
            },
            MuiTypography: {
                root: {
                    '&.text-button': {
                        color: `${primaryColor || RED_VIOLET}`,
                    },
                },
                h1: {
                    ...FONT_24,
                },
            },
            MuiRadio: {
                root: {
                    color: PRIMARY,
                },
                colorPrimary: {
                    '&$checked': {
                        color: `${primaryColor || RED_VIOLET}`,
                    },
                },
            },
            MuiFormControlLabel: {
                label: {
                    ...FONT_REGULAR,
                },
                root: {
                    marginBottom: -15,
                },
            },
            MuiDrawer: {
                paperAnchorRight: {
                    background: 'transparent',
                    boxShadow: 'none',
                },
            },
            MuiIcon: {
                root: {
                    color: PRIMARY,
                },
            },
            MuiButton: {
                root: {
                    borderRadius: 5,
                    '&.custom-button': {
                        color: `${primaryColor || RED_VIOLET}`,
                    },
                    '&.pinpoint-button': {
                        border: `2px solid ${primaryColor || RED_VIOLET}`,
                    },
                    '&.pinpointset-button': {
                        border: 'none',
                    },
                },
                containedPrimary: {
                    backgroundColor: `${primaryColor || RED_VIOLET}`,
                    '&:hover': {
                        backgroundColor: `${primaryColor || RED_VIOLET}`,
                    },
                },
            },
            MuiBadge: {
                colorPrimary: {
                    backgroundColor: `${primaryColor || RED_VIOLET}`,
                },
            },
            MuiPaper: {
                root: {
                    '&.payment-method': {
                        backgroundColor: `${primaryColor || RED_VIOLET}`,
                    },
                },
            },
            MuiInput: {
                root: {
                    borderBottom: 'red',
                },
                borderBottom: `2px solid ${primaryColor || RED_VIOLET}`,
                underline: {
                    '&:before': {
                        borderBottom: `2px solid ${primaryColor || RED_VIOLET}`,
                    },
                    '&:hover:not(.Mui-disabled):before': {
                        borderBottom: `2px solid ${primaryColor || RED_VIOLET}`,
                    },
                    '&:after': {
                        borderBottom: `2px solid ${primaryColor || RED_VIOLET}`,
                    },
                },
            },
            MuiFormControl: {
                root: {
                    '&.custom-textfield input': {
                        borderBottom: `2px solid ${primaryColor || RED_VIOLET} !important`,
                    },
                    '& #checkout-loginPhoneNumber-textField': {
                        borderBottom: `2px solid ${primaryColor || RED_VIOLET} !important`,
                    },
                    '& #checkout-loginOtp-textField': {
                        borderBottom: `2px solid ${primaryColor || RED_VIOLET} !important`,
                    },
                },
            },
            MuiInputLabel: {
                root: {
                    color: `${primaryColor || RED_VIOLET}`,
                },
            },
            MuiSvgIcon: {
                root: {
                    '&.custom-svg': {
                        color: `${primaryColor || RED_VIOLET}`,
                    },
                    '& .delivery_svg__a': {
                        stroke: `${primaryColor || RED_VIOLET}`,
                    },
                    '&.pickup-person': {
                        fill: `${primaryColor || RED_VIOLET}`,
                    },
                    '& .location_svg__a': {
                        stroke: `${primaryColor || RED_VIOLET}`,
                    },
                    '&.phone-icon': {
                        '& g': {
                            fill: `${primaryColor || RED_VIOLET}`,
                        },
                    },
                    '&.checkout-payment-payment-img': {
                        '& .payment_svg__b': {
                            stroke: `${primaryColor || RED_VIOLET}`,
                        },
                        '& g': {
                            stroke: `${primaryColor || RED_VIOLET}`,
                        },
                    },
                },
            },
            MuiCheckbox: {
                root: {
                    color: PRIMARY,
                },
                colorPrimary: {
                    '&$checked': {
                        color: `${primaryColor || RED_VIOLET}`,
                    },
                },
            },
            MuiDialogTitle: {
                root: {
                    '&.custom-dialog--title': {
                        backgroundColor: `${primaryColor || RED_VIOLET}`,
                    },
                },
            },
        },
    });
};

export default theme;
